import { useCallback, useMemo, useReducer, useState } from 'react';
import { ClientState } from '../../types/store/client';
import clientReducer from './clientReducer';
import {
  ClientPreference,
  KeyAttributesData,
} from '../../types/treatmentRecord/question';
import { generateClientPreferences } from '../../utils/helper-functions/intakeForm';
import useIntakeJSON from './useIntakeJSON';

export const initialClientState: ClientState = {
  id: '',
  firstName: '',
  lastName: '',
  profileImageUrl: '',
  originalEmail: '',
  shopifyId: '',
  boulevardId: ''
};

const useClientState = () => {
  const [refresh, setRefresh] = useState<number>(0);
  const [clientState, clientDispatch] = useReducer(
    clientReducer,
    initialClientState
  );
  const [selectedKeyAttributes, setSelectedKeyAttributes] = useState<
    ClientPreference[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const intakeJson = useIntakeJSON()!;

  const keyAttributes = useMemo(() => {
    return generateClientPreferences(
      clientState,
      intakeJson
    ) as KeyAttributesData;
  }, [clientState, intakeJson, refresh]);

  const refreshKeyAttributes = useCallback(() => setRefresh(refresh + 1), []);

  return {
    clientState,
    clientDispatch,
    keyAttributes,
    selectedKeyAttributes,
    setSelectedKeyAttributes,
    refreshKeyAttributes,
    intakeJson,
  };
};
export default useClientState;
