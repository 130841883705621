import dayjs from 'dayjs';
import {
  BACKBAR_TYPE,
  DEFAULT_TIMEZONE,
  LOCAL_STORAGE_KEYS,
} from '../../constants/appConstants';
import { REMOVE_LOCATION_LIST } from '../../constants/Helpers';
import { S3JSONTypes } from '../../providers/context/IntakeClientContext';
import { Location } from '../../types/services/Location';
import { IntakeFormState } from '../../types/store/form';
import {
  IntakeQuestionJson,
  IntakeSectionJsonTypes,
  IntakeSectionTypes,
  Question,
  QuestionOptions,
} from '../../types/treatmentRecord/question';
import { IS3Json } from '../../types/treatmentRecord/s3Json';
import { getLocalStorageItem } from './user';
import moment from 'moment-timezone';
import { SetStateAction, Dispatch } from 'react';
import { TreatmentTypes } from '../../types/clientProfile';
import {
  TreatmentFormKeysByType,
  TreatmentForm,
} from '../../types/services/Treatment';
import { laserTreatmentRecordSchema } from '../yup-schema/laserTreatmentRecord';
import { treatmentRecordSchema } from '../yup-schema/treatmentRecord';
import { heydayTreatmentRecordSchema } from '../yup-schema/heydayTreatmentRecord';
import { massagePressureSubLabel } from '../../constants/intakeConstants';

export const getGreetingMessage = () => {
  const currentHour = new Date().getHours();
  const greetingMessage = () => {
    if (currentHour >= 0 && currentHour < 13) {
      return 'Good Morning ☕️';
    } else if (currentHour >= 12 && currentHour <= 16) {
      return 'Good Afternoon ☀️';
    } else if (currentHour > 16 || currentHour <= 23) {
      return 'Good Evening 🌙';
    } else {
      return 'Welcome';
    }
  };
  return greetingMessage();
};

export const textTrimmer = (data: string): string => {
  return data.trim();
};

export const containSpecialChar = (name: string): boolean => {
  return /^[A-Za-z\s]+$/.test(name);
};

export const numberOnly = (data: string): boolean => {
  return /^\d+$/.test(data);
};

export const zipCodeValidator = (zipCode: string): boolean => {
  const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(textTrimmer(zipCode));
  return isValidZip;
};

export const dateFormatValidator = (date: string): boolean => {
  const testDate = date ? dayjs(date).format('MM/DD/YYYY') : '';
  const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return date_regex.test(testDate);
};

export const formatPhoneNumber = (number: string): string => {
  if (number.length === 10)
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
  if (number.length === 12 && number.startsWith('+'))
    return `${number.slice(0, 2)} (${number.slice(2, 5)}) ${number.slice(
      5,
      8
    )}-${number.slice(8)}`;
  return number;
};

type intakeOption = {
  id: string;
  label: string;
  value: string;
  subLabel?: string;
};

export const getJsonData = (
  setAllAnswers: Dispatch<SetStateAction<Record<string, string>>>,
  setSectionData: (result: S3JSONTypes) => void
) => {
  fetch(
    `${process.env.REACT_APP_INTAKE_HEALTH_JSON_URL}form-builder/health-intake-builder.json`,
    {
      headers: {
        'Cache-Control': 'no-cache',
      },
    }
  ).then(async (res) => {
    const json = await res.json();
    setAllAnswers(json.intakeOptions);
    const result = Object.entries(json.intakeSections).reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc: S3JSONTypes, [a, b]: any) => {
        acc[a] = { id: a, ...b };
        acc[a]['questionData'] = b.questionIds.map((q: string) => {
          const optList = json.intakeQuestions[q];
          optList.optionsData = optList.options.map((o: string) => {
            const option: intakeOption = {
              id: o,
              label: json.intakeOptions[o],
              value: json.intakeOptions[o],
            };
            if (
              optList.questionId === 'massagePressure' &&
              o === massagePressureSubLabel[0]
            )
              option.subLabel = massagePressureSubLabel[1];
            return option;
          });
          return { id: q, ...optList };
        });
        return acc;
      },
      {}
    );
    setSectionData(result);
  });
};

export const getFilterData = (
  sectionLocalData: IntakeSectionTypes[],
  sectionJsonData: IntakeSectionJsonTypes,
  customerSectionData: IntakeFormState[]
) => {
  sectionLocalData.forEach((sectionData: IntakeSectionTypes) => {
    sectionJsonData?.questionData.forEach((jsonData: IntakeQuestionJson) => {
      const ind = customerSectionData?.findIndex(
        (a: IntakeFormState) => a.question_id === jsonData.id
      );
      if (sectionData.questionId === jsonData.questionId) {
        sectionData.defaultError = jsonData.defaultError;
        sectionData.id = jsonData.id;
        sectionData.options = jsonData.optionsData;
        sectionData.jsonData = jsonData;
        sectionData.mandatory = jsonData.mandatory;
        if (ind > -1) {
          sectionData.selectedOptions =
            customerSectionData[ind]?.answer.length > 0
              ? customerSectionData[ind].answer
              : customerSectionData[ind]?.other_answer;
          sectionData.otherAnswer = customerSectionData[ind]?.other_answer;
        }
      }
    });
  });

  return sectionLocalData;
};

const s3TreatmentJson = (): IS3Json =>
  JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE_KEYS.S3_TREATMENT_JSON) as string
  );

export const getOptionsLabel = (value: string | string[] | null): string => {
  const options = s3TreatmentJson() || {};
  if (typeof value === 'string') {
    return options[value] ? options[value] : value;
  } else if (typeof value === 'object') {
    return value?.length
      ? value.map((val: string) => options[val]).join(', ')
      : 'NA';
  } else {
    return 'NA';
  }
};

export const getOptionIds = (label: string) => {
  const options = s3TreatmentJson() || {};
  return Object.keys(options).find((key) => options[key] === label);
};

export const uniqueValues = (value: string, index: number, self: string[]) => {
  return self.indexOf(value) === index;
};

export const duplicateEmail = (email: string) => {
  return email?.indexOf('urn:blvd:Client:') > -1 && !email?.includes('@')
    ? 'Missing Email'
    : email?.indexOf('urn:blvd:Client:') > -1 && !!email.includes('@');
};

export const filterLocations = (data: Location[]) => {
  const filterDate = data.filter(
    (a: Location) => REMOVE_LOCATION_LIST.indexOf(a.name.toLowerCase()) === -1
  );
  return filterDate;
};

export const promiseBasedTimeout = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, ms);
  });
};

export const getName = (data?: {
  firstName?: string;
  lastName?: string;
  name?: string | null;
}) => {
  return data?.firstName
    ? `${data?.firstName} ${data?.lastName ?? ''}`
    : data?.name ?? '';
};

export const getDateWithTimezone = (date: Date, timezone?: string) => {
  return moment.tz(date, timezone ?? DEFAULT_TIMEZONE);
};

export const getTrBgColor = (
  treatmentType: string,
  backbarType: string | undefined
) => {
  if (treatmentType === TreatmentTypes.laser) return 'var(--positive-primary)';
  return backbarType === BACKBAR_TYPE.heyday
    ? 'var(--neutral-tertiary-dark)'
    : 'var(--sky-50)';
};

export const getTreatmentSchema = (attributes: Partial<TreatmentForm>) => {
  if (attributes?.treatmentType === TreatmentTypes.laser)
    return laserTreatmentRecordSchema;
  return attributes?.formType === BACKBAR_TYPE.heyday
    ? heydayTreatmentRecordSchema
    : treatmentRecordSchema;
};

export const deleteElementFromAttributes = (
  element: Question,
  attributes: TreatmentForm
) => {
  if (element.options?.length) {
    element.options.forEach((option) => {
      deleteOptionFromAttributes(option, attributes);
    });
  }
  if (attributes[element.id as keyof TreatmentForm]) {
    let key = element.id as keyof TreatmentForm | undefined;
    switch (typeof attributes[element.id as keyof TreatmentForm]) {
      case 'boolean':
        key = key as TreatmentFormKeysByType<boolean>;
        if (key) attributes[key] = false;
        break;

      case 'string':
        key = key as TreatmentFormKeysByType<string>;
        if (key && key !== 'formType') attributes[key] = '';
        break;

      case 'object':
        key = key as TreatmentFormKeysByType<object>;
        if (key) attributes[key] = [];
        break;

      default:
        delete attributes[element.id as keyof TreatmentForm];
        break;
    }
  }
};

export const deleteOptionFromAttributes = (
  option: QuestionOptions,
  attributes: TreatmentForm
) => {
  if (option.elements?.length) {
    option.elements.forEach((el) =>
      deleteElementFromAttributes(el, attributes)
    );
  }
};
