import { TLoadingDispatch, TLoadingState } from './types';

export const loadingReducer = (
  state: TLoadingState,
  action: TLoadingDispatch
) => {
  if (action.type === 'SET_CLIENT_DETAILS_LOADING') {
    const newState = { ...state };
    newState.clientDetailsLoading = action.payload;
    return newState;
  } else if (action.type === 'SET_TREATMENT_LOADING') {
    const newState = { ...state };
    newState.isTreatmentLoading = action.payload;
    return newState;
  } else {
    return state;
  }
};
