import { getLocalStorageItem } from '../utils/helper-functions/user';
import { LOCAL_STORAGE_KEYS } from './appConstants';

type TS3JSON = Record<string, string>;

const s3jsonConstants: TS3JSON = {};

export const updateS3JsonConstant = (s3Data: TS3JSON) => {
  for (const key in s3Data) {
    s3jsonConstants[s3Data[key]] = key;
  }
};
updateS3JsonConstant(
  JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE_KEYS.S3_TREATMENT_JSON) as string
  )
);

export default s3jsonConstants;
