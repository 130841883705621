import { object, string, array, bool } from 'yup';
import { treatmentRecordCommonSchema } from './treatmentRecordCommon';
import s3jsonConstants from '../../constants/s3jsonConstants';
import { facialTreatmentRecordCommonSchema } from './facialTreatmentRecordCommon';

const isScalpRevival = (maskEnhancements: string[] | undefined) =>
  maskEnhancements?.includes(s3jsonConstants['Soothing Scalp Revival'])
    ? true
    : maskEnhancements?.includes(s3jsonConstants['Soothing Scalp Revival']);

export const heydayTreatmentRecordSchema = object().shape({
  treatmentAttributes: object().shape({
    exfoliationLevel: string()
      .when(['exfoliationEnhancement', 'exfoliationSegment'], {
        is: (
          enhancement?: string[] | undefined,
          segment?: boolean | undefined
        ) => (enhancement?.length === 0 || !enhancement) && !!segment,
        then: string().required('Exfoliation Level'),
      })
      .nullable(true),
    levelOneExfoliationBase: array(string())
      .when('exfoliationLevel', {
        is: (exfoliationLevel: string) =>
          exfoliationLevel === s3jsonConstants['Level 1 Exfoliation'],
        then: array(string())
          .required('Exfoliation Base')
          .min(1, 'Exfoliation Base'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    levelOneExfoliationActive: string()
      .when('exfoliationLevel', {
        is: (exfoliationLevel: string) =>
          exfoliationLevel === s3jsonConstants['Level 1 Exfoliation'],
        then: string().required('Exfoliation Active'),
      })
      .nullable(true),
    levelTwoExfoliationBase: array(string())
      .when('exfoliationLevel', {
        is: (exfoliationLevel: string) =>
          exfoliationLevel === s3jsonConstants['Level 2 Exfoliation'],
        then: array(string())
          .required('Exfoliation Base')
          .min(1, 'Exfoliation Base'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    levelTwoExfoliationActive: array(string())
      .when('exfoliationLevel', {
        is: (exfoliationLevel: string) =>
          exfoliationLevel === s3jsonConstants['Level 2 Exfoliation'],
        then: array(string())
          .required('Exfoliation Active')
          .length(2, 'Exfoliation Active'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    levelThreeExfoliationActive: string()
      .when('exfoliationLevel', {
        is: (exfoliationLevel: string) =>
          exfoliationLevel === s3jsonConstants['Level 3 Exfoliation'],
        then: string().required('Exfoliation Active'),
      })
      .nullable(true),
    levelFourExfoliationActive: array(string())
      .when('exfoliationLevel', {
        is: (exfoliationLevel: string) =>
          exfoliationLevel === s3jsonConstants['Level 4 Exfoliation'],
        then: array(string())
          .required('Exfoliation Active')
          .length(2, 'Exfoliation Active'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    extractionsPrepMethod: array(string())
      .when('extractionsSegment', {
        is: true,
        then: array(string())
          .required('Extraction Prep Method')
          .min(1, 'Extraction Prep Method'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    extractionsMmpMassageBase: array(string())
      .when('extractionsPrepMethod', {
        is: (extractionsPrepMethod: string[] | undefined) =>
          extractionsPrepMethod?.includes(
            s3jsonConstants['Massage Method Prep']
          ),
        then: array(string()).required('Massage Base').min(1, 'Massage Base'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    extractionsDmpProduct: array(string())
      .when('extractionsPrepMethod', {
        is: (extractionsPrepMethod: string[] | undefined) =>
          extractionsPrepMethod?.includes(
            s3jsonConstants['Desincrustation Method Prep']
          ),
        then: array(string())
          .required('Desincrustation Product')
          .min(1, 'Desincrustation Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    touchSegment: bool().nullable(true),
    touchSegmentMassageBase: array(string())
      .when('touchSegment', {
        is: true,
        then: array(string())
          .required('Touch Point Massage Base')
          .min(1, 'Touch Point Massage Base'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    maskBaseProduct: array(string())
      .when(['maskEnhancements', 'maskSegment'], {
        is: (enhancement: string[] | undefined, segment: boolean | undefined) =>
          (enhancement?.length === 0 || !enhancement) && !!segment,
        then: array(string())
          .required('Mask Base Product')
          .min(1, 'Mask Base Product'),
      })
      .nullable()
      .transform((v) => (v === null ? [] : v)),
    maskHwiWandProduct: array(string())
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: array(string()).required('Mask Enhancements Wand Product'),
      })
      .nullable()
      .transform((v) => (v === null ? [] : v)),
    maskHwiSuctionRate: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: string().required('Mask Enhancements Wand Serum'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskMicrocurrentProduct: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(s3jsonConstants['Microcurrent — $80']),
        then: string().required('Mask Segment Microcurrent Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),

    maskNanoinfusionDeviceOrRoomNumber: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
        then: string().required('Nano Infusion Device or Room Number'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskNanoinfusionNanoMask: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
        then: string().required('Nano Infusion Nano Mask'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskSbMask: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          isScalpRevival(maskEnhancements),
        then: string().required('Soothing Scalp Revival Mask'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskSbMicrocurrentLong: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          isScalpRevival(maskEnhancements),
        then: string().required('Soothing Scalp Revival Microcurrent Long'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskSbIntensity: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          isScalpRevival(maskEnhancements),
        then: string().required('Soothing Scalp Revival Intensity'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    sosBaseProduct: string()
      .when('sosSegment', {
        is: true,
        then: string().required('SOS Base Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    moisturizerAndSpf: array(string())
      .when('rRMoisturizerAndSpfTp', {
        is: (val: string) => !val,
        then: array(string())
          .required('Moisturizer & SPF')
          .min(1, 'Moisturizer & SPF'),
      })
      .nullable()
      .transform((v) => (v === null ? [] : v)),
    choose_one: bool().test(
      'atleast_at least one',
      'Choose atleast one segment',
      function () {
        const selectedSegment = [
          this.parent.exfoliationSegment,
          this.parent.extractionsSegment,
          this.parent.sosSegment,
          this.parent.touchSegment,
          this.parent.maskSegment,
        ].filter((segment) => segment).length;
        return selectedSegment >= 1;
      }
    ),
    ...treatmentRecordCommonSchema.treatmentAttributes,
    ...facialTreatmentRecordCommonSchema,
  }),
  ...treatmentRecordCommonSchema.topLevel,
});
