import {
  ClientPreference,
  ClientPreferencesCategory,
  IntakeSectionTypes,
} from '../../types/treatmentRecord/question';
import { IntakeFormState } from '../../types/store/form';
import {
  IIntakeJSON,
  IIntakeJSONQuestion,
  IIntakeJSONSection,
} from '../../types/services/HealthIntake';
import { ClientState } from '../../types/store/client';

export const radioWithTextfieldValidation = (
  a: IntakeSectionTypes,
  userSelections: IntakeFormState[],
  optionId: string
) => {
  const dataIndex = userSelections.findIndex((k) => {
    return k.question_id === a.id;
  });
  if (dataIndex === -1) {
    return true;
  }
  if (optionId === userSelections[dataIndex].answer[0]) {
    return userSelections[dataIndex].other_answer.length <= 0;
  }
};

const createPreference = (
  questionId: string,
  optionId: string,
  questionData: IIntakeJSONQuestion,
  optionLabel: string
) => ({
  cp_category_id: questionData.questionId,
  cp_label: optionLabel,
  optionId,
  questionId,
  selected: false,
  showOnCoreSegments: false,
});


export const generateClientPreferences = (
  clientState: ClientState,
  savedS3IntakeJson: IIntakeJSON | null
) => {
  if (!savedS3IntakeJson?.intakeSections) return;
  const preferencesSection = Object.values(
    savedS3IntakeJson.intakeSections
  ).find((section) => section.sectionId === 'preferences');

  const saved: ClientPreference[] = [];
  const categories: ClientPreferencesCategory[] = [];
  const preferences: ClientPreference[] = [];
  preferencesSection?.questionIds.forEach((questionId) => {
    const questionData = savedS3IntakeJson.intakeQuestions[questionId];
    // Handle saved data
    const savedQuestion = clientState.healthIntake?.find(
      (ques) => ques.questionId === questionId
    );
    savedQuestion?.answer.forEach((optionId) => {
      const optionLabel = savedS3IntakeJson.intakeOptions[optionId];
      saved.push(
        createPreference(questionId, optionId, questionData, optionLabel)
      );
    });

    // Create categories for form
    categories.push({
      cp_category_id: questionData.questionId,
      cp_category_title: questionData.questionTitle,
    });

    // Create preferences config for form
    questionData.options.forEach((optionId) => {
      const optionLabel = savedS3IntakeJson.intakeOptions[optionId];

      preferences.push(
        createPreference(questionId, optionId, questionData, optionLabel)
      );
    });
  });
  
  return {
    preferences,
    categories,
    saved,
  };
};

export const getKeyAttributesData = (
  intakeSections: { [key: string]: IIntakeJSONSection },
  selectedKeyAttributes: ClientPreference[],
  clientState: ClientState
) => {
  const preferencesSection = Object.values(intakeSections).find(
    (section) => section.sectionId === 'preferences'
  );

  const keyAttributesData = preferencesSection?.questionIds.map(
    (questionId) => {
      const questionAttributes = selectedKeyAttributes.filter(
        (selected) => selected.questionId === questionId
      );
      const savedIntakeRow = clientState?.healthIntake?.find(
        (row) => row.questionId === questionId
      );

      // Get options for respective questions
      const questionsWithOptions = questionAttributes.reduce(
        (grouped, attribute) => {
          if (grouped?.answer?.length) {
            grouped.answer.push(attribute.optionId);
          } else {
            grouped = {
              question_id: attribute.questionId,
              answer: [attribute.optionId],
              other_answer: '',
              id: savedIntakeRow?.id,
            };
          }
          return grouped;
        },
        {} as IntakeFormState
      );

      // If no answers just pass [] this handles attributes removal
      return questionsWithOptions.question_id
        ? questionsWithOptions
        : {
            question_id: questionId,
            answer: [],
            other_answer: '',
            id: savedIntakeRow?.id,
          };
    }
  );
  return keyAttributesData as IntakeFormState[];
};
