import { useEffect, useState } from 'react';
import { IIntakeJSON } from '../../types/services/HealthIntake';
import { FORMAT_DATE, LOCAL_STORAGE_KEYS } from '../../constants/appConstants';
import moment from 'moment-timezone';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/helper-functions/user';

const useIntakeJSON = () => {
  const [intakeJson, setIntakeJson] = useState<IIntakeJSON | null>(null);
  
  const fetchIntakeSkinProfileJson = async () => {
    return fetch(
      process.env.REACT_APP_INTAKE_HEALTH_JSON_URL +
        'form-builder/health-intake-builder.json' || ''
    )
      .then((response) => response.json())
      .then((response) => {
        console.log('response', response);
        setIntakeJson(response);
        const saveData = {
          savedDate: moment().format(FORMAT_DATE.DAY),
          data: response,
        };
        setLocalStorageItem(
          LOCAL_STORAGE_KEYS.S3_HEALTH_INTAKE,
          JSON.stringify(saveData)
        );
      })
      .catch((error) => {
        console.log(error, 'failed to fetch fetch intake S3json');
      });
  };

  const loadIntakeData = async () => {
    try {
      const savedS3Intake = getLocalStorageItem(
        LOCAL_STORAGE_KEYS.S3_HEALTH_INTAKE
      );

      if (savedS3Intake) {
        const savedS3IntakeJson = JSON.parse(savedS3Intake);

        if (savedS3IntakeJson?.savedDate === moment().format(FORMAT_DATE.DAY)) {
          return setIntakeJson(savedS3IntakeJson.data);
        }
      }

      await fetchIntakeSkinProfileJson();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadIntakeData();
  }, []);

  return intakeJson;
};

export default useIntakeJSON;
