import { array, string } from 'yup';
import { getNote } from '../helper-functions/getNote';
import { NOTE_TYPE } from '../../constants/appConstants';

export const treatmentRecordCommonSchema = {
  topLevel: {
    note: array().test('clientMessage', 'Client Message', (value) => {
      const noteForCustomerText =
        getNote(NOTE_TYPE.noteForCustomer, value)?.text?.trim() ?? '';
      return Boolean(
        noteForCustomerText &&
          noteForCustomerText.length > 10 &&
          /^[a-zA-Z0-9]/.exec(noteForCustomerText)
      );
    }),
    recommendedProduct: array()
      .required('Product Recommendations')
      .min(1, 'Product Recommendations')
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
  },
  treatmentAttributes: {
    skinType: string().required('Skin Type').nullable(true),
    conditions: array(string())
      .required('Conditions')
      .min(1, 'Conditions')
      .nullable(true),
    topTreatmentFocus: array(string())
      .required('Top Treatment Focus')
      .min(1, 'Top Treatment Focus')
      .max(3, 'Top Treatment Focus')
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    cleanseBaseProduct: array(string())
      .when('cleanseBaseProductTp', {
        is: (val: string) => !val,
        then: array(string())
          .required('Cleanse Base Products')
          .min(1, 'Cleanse Base Products'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
  },
};
