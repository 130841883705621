import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { TRoute } from '../../types/commonTypes';

type TuseRenderRoutes = (
  routes: TRoute[],
  singleDefaultRoute?: { path: string }
) => { routesDOM: React.JSX.Element };

export const useRenderRoutes: TuseRenderRoutes = (
  routes,
  singleDefaultRoute
) => {
  const { path } = useRouteMatch();

  const defaultRoute =
    singleDefaultRoute ?? routes.find((route) => route.default);

  const routesDOM = (
    <Switch>
      {routes.map((route: TRoute) => {
        return (
          <Route
            key={`path-${path}-${route.path}`}
            path={`${path}${route.path}`}
            exact={route.exact}
            // eslint-disable-next-line react/no-children-prop
            children={() => {
              return (
                <>
                  {route?.provider ? (
                    <route.provider>
                      <route.component />
                    </route.provider>
                  ) : (
                    <route.component />
                  )}
                </>
              );
            }}
          />
        );
      })}
      {defaultRoute ? (
        <Route key={`redirect`}>
          <Redirect to={{ pathname: `${path}${defaultRoute.path}` }} />
        </Route>
      ) : null}
    </Switch>
  );

  return { routesDOM };
};
