import { array, bool, string } from 'yup';
import s3jsonConstants from '../../constants/s3jsonConstants';

const isPlatinumParty = (maskEnhancements: string[] | undefined) =>
  maskEnhancements?.includes(s3jsonConstants['Platinum Party ($)'])
    ? true
    : maskEnhancements?.includes(s3jsonConstants['Platinum Party – $65']);

const generateSVZoneValidation = (zone: number) =>
  string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(s3jsonConstants['Dermaplaning ($$)']),
      then: string().required(
        `Exfoliation Enhancements Zone ${zone} Stroke Volume`
      ),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v));

export const facialTreatmentRecordCommonSchema = {
  exfoliationSegment: bool().nullable(true),
  sosSegment: bool().nullable(true),
  exfoliationEnhancement: array(string()).nullable(true),
  exfoliationDtmWand: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(
          s3jsonConstants['Diamond Tip Microderm — $80']
        ),
      then: string().required('Exfoliation Enhancements Wand'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  exfoliationDtmSuctionRate: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(
          s3jsonConstants['Diamond Tip Microderm — $80']
        ),
      then: string().required('Exfoliation Enhancements Suction Rate'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  exfoliationDtmPasses: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(
          s3jsonConstants['Diamond Tip Microderm — $80']
        ),
      then: string().required('Exfoliation Enhancements Passes'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  exfoliationHwmWandProduct: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(
          s3jsonConstants['Hydro Wand Microderm — $80']
        ),
      then: string().required('Exfoliation Enhancements Wand Product'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  exfoliationHwmSuctionRate: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(
          s3jsonConstants['Hydro Wand Microderm — $80']
        ),
      then: string().required('Exfoliation Enhancements Suction Rate'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  exfoliationHwmPasses: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(
          s3jsonConstants['Hydro Wand Microderm — $80']
        ),
      then: string().required('Exfoliation Enhancements Passes'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  extractionsZone: array(string())
    .when('extractionsSegment', {
      is: true,
      then: array(string())
        .required('Extractions Zone')
        .min(1, 'Extractions Zone'),
    })
    .nullable(true)
    .transform((v) => (v === null ? [] : v)),
  exfoliationStrokeVolumeZoneOne: generateSVZoneValidation(1),
  exfoliationStrokeVolumeZoneTwo: generateSVZoneValidation(2),
  exfoliationStrokeVolumeZoneThree: generateSVZoneValidation(3),
  exfoliationStrokeVolumeZoneFour: generateSVZoneValidation(4),
  maskEnhancements: array(string()).nullable(true),
  maskPpMask: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        isPlatinumParty(maskEnhancements),
      then: string().required('Platinum Party Mask'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskPpMicrocurrentProgram: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        isPlatinumParty(maskEnhancements),
      then: string().required('Platinum Party Microcurrent Program'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskPpMicrocurrentIntensity: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        isPlatinumParty(maskEnhancements),
      then: string().required('Platinum Party Microcurrent Intensity'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskPpAdditionalTouchpoint: array(string())
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        isPlatinumParty(maskEnhancements),
      then: array(string())
        .required('Platinum Party Additional Touchpoint')
        .min(1, 'Platinum Party Additional Touchpoint'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskLedPanelTime: string()
    .when('maskEnhancements', {
      is: (maskEnhancements?: string[]) =>
        maskEnhancements?.includes(s3jsonConstants['LED Panel — $50']),
      then: string().required('Mask Segment LED Panel Time'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  maskLedTouchpoint: array(string())
    .when('maskEnhancements', {
      is: (maskEnhancements?: string[]) =>
        maskEnhancements?.includes(s3jsonConstants['LED Panel — $50']),
      then: array(string())
        .required('Mask Segment LED Touchpoint')
        .min(1, 'Mask Segment LED Touchpoint'),
    })
    .nullable(true)
    .transform((v) => (v === null ? [] : v)),
  maskMicrocurrentProgramLpi: string()
    .when('maskEnhancements', {
      is: (maskEnhancements?: string[]) =>
        maskEnhancements?.includes(s3jsonConstants['Microcurrent — $80']),
      then: string().required('Mask Segment Long Program Intesity'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  maskMicrocurrentProgramSpi: string()
    .when('maskEnhancements', {
      is: (maskEnhancements?: string[]) =>
        maskEnhancements?.includes(s3jsonConstants['Microcurrent — $80']),
      then: string().required('Mask Segment Short Program Intensity'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  maskNanoinfusionStitchDepthFcc: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
      then: string().required(
        'Nano Mask Stitch depth for forehead, cheeks, and chin'
      ),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskNanoinfusionSpeedFcc: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
      then: string().required('Nano Mask Speed for forehead, cheeks, and chin'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskNanoinfusionStitchDepthNe: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
      then: string().required('Nano Mask Stitch depth for nose and eyes'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  maskNanoinfusionSpeedNe: string()
    .when('maskEnhancements', {
      is: (maskEnhancements: string[] | undefined) =>
        maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
      then: string().required('Nano Mask Speed for nose and eyes'),
    })
    .nullable()
    .transform((v) => (v === null ? '' : v)),
  exfoliationPeelProduct: array(string())
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(s3jsonConstants['Peel — $50']),
      then: array(string())
        .required('Exfoliation Enhancements Peel Product')
        .min(1, 'Exfoliation Enhancements Peel Product'),
    })
    .nullable(true)
    .transform((v) => (v === null ? [] : v)),
  exfoliationPeelTimeOnSkin: string()
    .when('exfoliationEnhancement', {
      is: (exfoliationEnhancement: string[]) =>
        exfoliationEnhancement?.includes(s3jsonConstants['Peel — $50']),
      then: string().required('Exfoliation Enhancements Time on Skin'),
    })
    .nullable(true)
    .transform((v) => (v === null ? '' : v)),
  extractionsSegment: bool().nullable(true),
  maskSegment: bool().nullable(true),
  nextTimeEnhancement: array()
    .required('Next Time Enhancements')
    .min(1, 'Next Time Enhancements')
    .nullable(true)
    .transform((v) => (v === null ? [] : v)),
};
