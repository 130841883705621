import moment from 'moment-timezone';
import { Note } from '../../types/services/Note';
import _ from 'lodash';
import { NOTE_TYPE } from '../../constants/appConstants';

export const getNote = (noteType: NOTE_TYPE, data?: Note[]) => {
  if (!data) return undefined;

  const sortedNotes = [...data].sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt))
  );
  return _.findLast(sortedNotes, (note) => note.type === noteType);
};
