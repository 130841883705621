import { object, string, array, bool } from 'yup';
import { treatmentRecordCommonSchema } from './treatmentRecordCommon';
import s3jsonConstants from '../../constants/s3jsonConstants';
import { facialTreatmentRecordCommonSchema } from './facialTreatmentRecordCommon';

export const treatmentRecordSchema = object().shape({
  treatmentAttributes: object().shape({
    exfoliationHwiWandProduct: string()
      .when('exfoliationEnhancement', {
        is: (exfoliationEnhancement: string[]) =>
          exfoliationEnhancement?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: string().required('Exfoliation Enhancements Wand Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    exfoliationHwiTreatmentSerum: array(string())
      .when('exfoliationEnhancement', {
        is: (exfoliationEnhancement: string[]) =>
          exfoliationEnhancement?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: array(string())
          .required('Exfoliation Enhancements treatment serum')
          .min(1, 'Exfoliation Enhancements treatment serum')
          .max(2, 'Exfoliation Enhancements treatment serum'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    exfoliationHwiSuctionRate: string()
      .when('exfoliationEnhancement', {
        is: (exfoliationEnhancement: string[]) =>
          exfoliationEnhancement?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: string().required('Exfoliation Enhancements Suction Rate'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    exfoliationPeelLayers: string()
      .when('exfoliationEnhancement', {
        is: (exfoliationEnhancement: string[]) =>
          exfoliationEnhancement?.includes(s3jsonConstants['Peel — $50']),
        then: string().required('Exfoliation Enhancements Peel Layers'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    extractionsPrepProducts: array(string())
      .when('extractionsSegment', {
        is: true,
        then: array(string())
          .required('Extraction Prep Products')
          .min(1, 'Extraction Prep Products'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    extractionsPostProducts: array(string())
      .when('extractionsSegment', {
        is: true,
        then: array(string())
          .required('Extraction Post Products')
          .min(1, 'Extraction Post Products'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    touchPointSegment: bool().nullable(true),
    touchPointMassageMedium: string()
      .when('touchPointSegment', {
        is: true,
        then: string().required('Touch Point Massage Medium'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    maskHwiWandProduct: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: string().required('Mask Segment Wand Product'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskHwiTreatmentSerum: array(string())
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: array(string())
          .required('Mask Segment Treatment Serum')
          .min(1, 'Mask Segment Treatment Serum')
          .max(2, 'Mask Segment Treatment Serum'),
      })
      .nullable()
      .transform((v) => (v === null ? [] : v)),
    maskHwiSuctionRate: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(
            s3jsonConstants['Hydro Wand Infusion — $80']
          ),
        then: string().required('Mask Segment Suction Rate'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskMicrocurrentProduct: array(string())
      .when('maskEnhancements', {
        is: (maskEnhancements: string[]) =>
          maskEnhancements?.includes(s3jsonConstants['Microcurrent — $80']),
        then: array(string())
          .required('Mask Segment Microcurrent Product')
          .min(1, 'Mask Segment Microcurrent Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    maskMicrocurrentSerum: array(string())
      .when('maskEnhancements', {
        is: (maskEnhancements: string[]) =>
          maskEnhancements?.includes(s3jsonConstants['Microcurrent — $80']),
        then: array(string())
          .required('Mask Segment Microcurrent Serum')
          .min(1, 'Mask Segment Microcurrent Serum'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    maskMicrocurrentTreatmentTime: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[]) => {
          return maskEnhancements?.includes(
            s3jsonConstants['Microcurrent — $80']
          );
        },
        then: string().required('Mask Segment Microcurrent Treatment Time'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    maskNanoinfusionDeviceUsed: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
        then: string().required('Mask Segment Nano Infusion Device Used'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskNanoinfusionSerumUsed: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
        then: string().required('Mask Segment Nano Infusion Serum Used'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    maskNanoinfusionMaskUsed: string()
      .when('maskEnhancements', {
        is: (maskEnhancements: string[] | undefined) =>
          maskEnhancements?.includes(s3jsonConstants['Nano Infusion -$80']),
        then: string().required('Mask Segment Nano Infusion Mask Used'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapySegment: bool().nullable(true),
    targetedTherapyEnhancements: array(string())
      .when('targetedTherapySegment', {
        is: true,
        then: array(string())
          .required('Targeted Therapy Enhancements')
          .min(1, 'Targeted Therapy Enhancements'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    targetedTherapyPpMask: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Platinum Party – $65']
          ),
        then: string().required('Targeted Therapy Platinum Party Mask'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyPpMicrocurrentProgram: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Platinum Party – $65']
          ),
        then: string().required(
          'Targeted Therapy Platinum Party Microcurrent Program'
        ),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyPpMicrocurrentIntensity: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Platinum Party – $65']
          ),
        then: string().required(
          'Targeted Therapy Platinum Party Microcurrent Intensity'
        ),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyPpAdditionalTouchpoint: array(string())
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Platinum Party – $65']
          ),
        then: array(string())
          .required('Targeted Therapy Platinum Party Additional Touchpoint')
          .min(1, 'Targeted Therapy Platinum Party Additional Touchpoint'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyLedPanelTime: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) => {
          return targetedTherapyEnhancements?.includes(
            s3jsonConstants['LED Panel — $50']
          );
        },
        then: string().required('Targeted Therapy Enhancements LED panel Time'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyLedTouchpoint: array(string())
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements?: string[]) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['LED Panel — $50']
          ),
        then: array(string())
          .required('Targeted Therapy Enhancements LED Touchpoint')
          .min(1, 'Targeted Therapy Enhancements LED Touchpoint'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    targetedTherapyMicrocurrentProgramLpi: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) => {
          return targetedTherapyEnhancements?.includes(
            s3jsonConstants['Microcurrent — $80']
          );
        },
        then: string().required(
          'Targeted Therapy Microcurrent Long Program Intesity'
        ),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyMicrocurrentProgramSpi: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) => {
          return targetedTherapyEnhancements?.includes(
            s3jsonConstants['Microcurrent — $80']
          );
        },
        then: string().required(
          'Targeted Therapy Microcurrent Short Program Intensity'
        ),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyMicrocurrentProduct: array(string())
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) => {
          return targetedTherapyEnhancements?.includes(
            s3jsonConstants['Microcurrent — $80']
          );
        },
        then: array(string())
          .required('Targeted Therapy Microcurrent Product')
          .min(1, 'Targeted Therapy Microcurrent Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    targetedTherapyMicrocurrentSerum: array(string())
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) => {
          return targetedTherapyEnhancements?.includes(
            s3jsonConstants['Microcurrent — $80']
          );
        },
        then: array(string())
          .required('Targeted Therapy Microcurrent Serum')
          .min(1, 'Targeted Therapy Microcurrent Serum'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    targetedTherapyMicrocurrentTreatmentTime: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[]) => {
          return targetedTherapyEnhancements?.includes(
            s3jsonConstants['Microcurrent — $80']
          );
        },
        then: string().required('Targeted Therapy Microcurrent Treatment Time'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),

    targetedTherapyNanoinfusionDeviceUsed: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required('Targeted Therapy Nano Infusion Device Used'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyNanoinfusionSerumUsed: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required('Targeted Therapy Nano Infusion Serum Used'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyNanoinfusionMaskUsed: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required('Targeted Therapy Nano Infusion Mask Used'),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),

    targetedTherapyNanoinfusionStitchDepthFcc: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required(
          'Targeted Therapy Nano Mask Stitch depth for forehead, cheeks, and chin'
        ),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyNanoinfusionSpeedFcc: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required(
          'Targeted Therapy Nano Mask Speed for forehead, cheeks, and chin'
        ),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyNanoinfusionStitchDepthNe: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required(
          'Targeted Therapy Nano Mask Stitch depth for nose and eyes'
        ),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    targetedTherapyNanoinfusionSpeedNe: string()
      .when('targetedTherapyEnhancements', {
        is: (targetedTherapyEnhancements: string[] | undefined) =>
          targetedTherapyEnhancements?.includes(
            s3jsonConstants['Nano Infusion -$80']
          ),
        then: string().required(
          'Targeted Therapy Nano Mask Speed for nose and eyes'
        ),
      })
      .nullable()
      .transform((v) => (v === null ? '' : v)),
    sosBaseProduct: array(string())
      .when('sosSegment', {
        is: true,
        then: array(string())
          .required('SOS Base Product')
          .min(1, 'SOS Base Product'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    sosSerum: array(string())
      .when('sosSegment', {
        is: true,
        then: array(string()).required('SOS Serum').min(1, 'SOS Serum'),
      })
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),
    choose_one: bool().test(
      'atleast_at least three',
      'Choose atleast three segments',
      function () {
        const selectedSegment = [
          this.parent.exfoliationSegment,
          this.parent.extractionsSegment,
          this.parent.sosSegment,
          this.parent.touchPointSegment,
          this.parent.maskSegment,
          this.parent.targetedTherapySegment,
        ].filter((segment) => segment).length;
        return selectedSegment >= 3;
      }
    ),
    ...treatmentRecordCommonSchema.treatmentAttributes,
    ...facialTreatmentRecordCommonSchema,
  }),
  ...treatmentRecordCommonSchema.topLevel,
});
