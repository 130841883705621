import { array, object, string } from 'yup';
import { treatmentRecordCommonSchema } from './treatmentRecordCommon';
import { getNote } from '../helper-functions/getNote';
import { NOTE_TYPE } from '../../constants/appConstants';

export const laserTreatmentRecordSchema = object().shape({
  treatmentAttributes: object().shape({
    skintelReading: string().required('Skintel Reading').nullable(true),
    featheringFacialWavelength: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('featheringFacial'),
        then: string().required('Feathering Facial Wavelength'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    featheringFacialSpotSize: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('featheringFacial'),
        then: string().required('Feathering Facial Spot Size'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    featheringFacialFluence: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('featheringFacial'),
        then: string().required('Feathering Facial Fluence'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    featheringFacialPulseWidth: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('featheringFacial'),
        then: string().required('Feathering Facial Pulse Width'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    featheringFacialRepRate: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('featheringFacial'),
        then: string().required('Feathering Facial Rep Rate'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    featheringFacialPulseCount: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('featheringFacial'),
        then: string().required('Feathering Facial Pulse Count'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),

    benignLesionsWavelength: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('benignPigmentedLesions'),
        then: string().required('Benign Pigmented Lesions Wavelength'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    benignLesionsSpotSizeAndPulseWidth: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('benignPigmentedLesions'),
        then: string().required(
          'Benign Pigmented Lesions Spot size and Pulse Width'
        ),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    benignLesionsFluence: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('benignPigmentedLesions'),
        then: string().required('Benign Pigmented Lesions Fluence'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    benignLesionsRepRate: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('benignPigmentedLesions'),
        then: string().required('Benign Pigmented Lesions Rep Rate'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    benignLesionsPulseCount: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('benignPigmentedLesions'),
        then: string().required('Benign Pigmented Lesions Pulse Count'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),

    vascularLesionsWavelength: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('vascularLesions'),
        then: string().required('Vascular Lesions Wavelength'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    vascularLesionsSpotSize: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('vascularLesions'),
        then: string().required('Vascular Lesions Spot Size'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    vascularLesionsFluence: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('vascularLesions'),
        then: string().required('Vascular Lesions Fluence'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    vascularLesionsPulseWidth: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('vascularLesions'),
        then: string().required('Vascular Lesions Pulse Width'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    vascularLesionsRepRate: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('vascularLesions'),
        then: string().required('Vascular Lesions Rep Rate'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),
    vascularLesionsPulseCount: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('vascularLesions'),
        then: string().required('Vascular Lesions Pulse Count'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),

    laserMask: string()
      .when('laserTreatmentType', {
        is: (laserTreatmentType: string[] | undefined) =>
          laserTreatmentType?.includes('sheetMask'),
        then: string().required('Sheet Mask'),
      })
      .nullable(true)
      .transform((v) => (v === null ? '' : v)),

    moisturizerAndSpf: array(string())
      .required('Moisturizer & SPF')
      .min(1, 'Moisturizer & SPF')
      .nullable(true)
      .transform((v) => (v === null ? [] : v)),

    ...treatmentRecordCommonSchema.treatmentAttributes,
  }),
  ...treatmentRecordCommonSchema.topLevel,
  note: treatmentRecordCommonSchema.topLevel.note.test(
    'internalNotes',
    'Internal Notes',
    (value) => {
      const treatmentNoteText =
        getNote(NOTE_TYPE.generalNotes, value)?.text?.trim() ?? '';
      return !!treatmentNoteText;
    }
  ),
});
