import { LOCAL_STORAGE_KEYS } from '../../constants/appConstants';
import {
  CUSTOMER_SESSION_TOKEN,
  NO_TOKEN_FOUND,
} from '../../constants/intakeConstants';
import { emailSchema } from '../yup-schema/user';

export const emailVerifier = (emailId: string): boolean => {
  try {
    emailSchema.validateSync(emailId);
    return true;
  } catch (error) {
    return false;
  }
};

export const getLocalStorageItem = (keyName: string) => {
  return window.localStorage.getItem(keyName);
};

export const tokenValidator = () => {
  const tokenValid = getLocalStorageItem(CUSTOMER_SESSION_TOKEN);
  if (!tokenValid) {
    throw new Error(NO_TOKEN_FOUND);
  }
};

export const setLocalStorageItem = (keyName: string, value: string) => {
  window.localStorage.setItem(keyName, value);
};

export const deleteLocalStorageItem = (keyName: string) => {
  return window.localStorage.removeItem(keyName);
};

export const clearLocalStorageItems = () => {
  const thoughtIndex = getLocalStorageItem(
    LOCAL_STORAGE_KEYS.THOUGHT_OF_THE_DAY_INDEX
  );
  const thoughtDate = getLocalStorageItem(
    LOCAL_STORAGE_KEYS.THOUGHT_OF_THE_DAY_DATE
  );
  localStorage.clear();
  setLocalStorageItem(
    LOCAL_STORAGE_KEYS.THOUGHT_OF_THE_DAY_INDEX,
    thoughtIndex ?? '0'
  );
  thoughtDate &&
    setLocalStorageItem(
      LOCAL_STORAGE_KEYS.THOUGHT_OF_THE_DAY_DATE,
      thoughtDate
    );
};
