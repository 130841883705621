export enum CLIENT_ACTION_TYPES {
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  UPDATE_TREATMENT = 'UPDATE_TREATMENT',
  UPDATE_NOTE = 'UPDATE_NOTE',
  UPDATE_PAST_PURCHASED_PRODUCT = 'UPDATE_PAST_PURCHASED_PRODUCT',
  UPDATE_PRODUCTS_TO_AVOID = 'UPDATE_PRODUCTS_TO_AVOID',
  UPDATE_KNACK_RECORDS = 'UPDATE_KNACK_RECORDS',
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
  UPDATE_MISSING_RECORDS = 'UPDATE_MISSING_RECORDS',
  UPDATE_CLEINT_NOTES = 'UPDATE_CLEINT_NOTES',
  UPDATE_HEALTH_INTAKE = 'UPDATE_HEALTH_INTAKE',
  REFRESH_CLIENT_ENDPOINT = 'REFRESH_CLIENT_ENDPOINT',
  RESET_CLIENT_STATE = 'RESET_CLIENT_STATE',
}
