import { createContext } from 'react';
import { TClientContext } from '../../types/store/client';
import { initialClientState } from './useClientState';

const ClientContext = createContext<TClientContext>({
  clientState: initialClientState,
  selectedKeyAttributes: [],
  keyAttributes: {
    categories: [],
    preferences: [],
    saved: [],
  },
  clientDispatch: () => ({}),
  setSelectedKeyAttributes: () => ({}),
  refreshKeyAttributes: () => ({}),
  intakeJson: {
    intakeSections: {},
    intakeOptions: {},
    intakeQuestions: {},
  },
});

export default ClientContext;
