import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/global.scss';
import * as Sentry from '@sentry/react';
import pkjJson from '../package.json';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: pkjJson.version,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        process.env.REACT_APP_X_SHOPIFY_MIDDLEWARE_API_BASEURL ?? '',
        process.env.REACT_APP_X_SHOPIFY_GRAPHQL_URL ?? '',
        process.env.REACT_APP_SOLVE_API_URL ?? '',
      ],
    }),
  ],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
