import {
  EnhancementRecommendation,
  iUTCOffset,
} from '../types/treatmentRecord/question';

// icons
import VaccineSVG from '../assets/icons/vaccine.svg';
import EmojiLovedSVG from '../assets/icons/emoji-loved.svg';
import EmployeeSVG from '../assets/icons/employee.svg';
import MemberSVG from '../assets/icons/member.svg';
import VaxExemption1SVG from '../assets/icons/vaxExemption1.svg';
import VaxExemption2SVG from '../assets/icons/vaxExemption2.svg';
import SPSensitivities from '../assets/icons/sp-sensitivities.svg';
import SPOilAcitivity from '../assets/icons/sp-oil-activities.svg';
import SPSkincare from '../assets/icons/sp-skincare.svg';
import SPBasic from '../assets/icons/sp-basics.svg';

import { ReactComponent as WarningCircle } from '../assets/icons/skin-profile-summary/WarningCircle.svg';
import { ReactComponent as rxBottle } from '../assets/icons/skin-profile-summary/rxBottle.svg';
import { ReactComponent as Robot } from '../assets/icons/skin-profile-summary/Robot.svg';
import { ReactComponent as prescription } from '../assets/icons/skin-profile-summary/prescription.svg';
import { ReactComponent as Leaf } from '../assets/icons/skin-profile-summary/Leaf.svg';
import { ReactComponent as Heartbeat } from '../assets/icons/skin-profile-summary/Heartbeat.svg';
import { ReactComponent as Fire } from '../assets/icons/skin-profile-summary/Fire.svg';
import { ReactComponent as CirclesThree } from '../assets/icons/skin-profile-summary/CirclesThree.svg';
import { ReactComponent as Baby } from '../assets/icons/skin-profile-summary/Baby.svg';
import { ReactComponent as Atom } from '../assets/icons/skin-profile-summary/Atom.svg';

import {
  FilterType,
  Metric,
  ProfileTag,
  QuestionLabel,
  SkinProfileQuestionTypes,
  SkinProfileSectionType,
} from '../types/clientProfile';

import { ReactComponent as ER_1 } from '../assets/icons/er-1.svg';
import { ReactComponent as ER_2 } from '../assets/icons/er-2.svg';
import { ReactComponent as ER_3 } from '../assets/icons/er-3.svg';
import { ReactComponent as ER_4 } from '../assets/icons/er-4.svg';

export const OUTSIDE_US = 'outside-us';
export const DEFAULT_NONE = 'none';
export const INTAKE_DEFAULT_NONE = '036ef926-ce64-464e-a830-edab78c273b4';
export const INTAKE_DEFAULT_OTHER = 'ecfff538-78c2-4a62-91f2-f4a3b73becd9';
export const INTAKE_YES = '3c14d1da-e206-4107-be72-f0922ac05704';
export const INTAKE_OTHERS = 'ecfff538-78c2-4a62-91f2-f4a3b73becd9';
export const RITTENHOUSE_LOCATION_ID = 'f5c40398-33f0-44b1-89cd-63b0ec9325c1';
export const PLYMOUTH_MEETING_LOCATION_ID =
  'f6e0050f-09d4-42a6-8b1f-59b303a1ae28';

export const AUTO_SAVE_MESSAGE = 'Changes auto saved';
export const AUTO_SAVE_INTERVAL = 5000;

export const APPOINTMENT_METRICS: Metric[] = [
  {
    title: 'Appointment count',
    key: 'appointmentsCount',
    label: 'APTS',
    value: '0',
    info: 'Number of completed appointments',
  },
  {
    title: 'Last appointment date',
    key: 'lastVisit',
    label: 'LAST VISIT',
    value: '--',
    info: 'Date of last completed appointment',
  },
  {
    title: 'Enhancement attachment Rate',
    key: 'enhancementRate',
    label: 'ENHAN RT',
    value: '--',
    info: 'Number of appointments with 1 or more enhancements divided by the total number of completed appointments',
  },
  {
    title: 'Product attachment rate',
    key: 'productAttritionRate',
    label: 'PROD ATT RT',
    value: '--',
    info: 'Number of transactions with both service and product purchases divided by the total number of completed appointments',
  },
];

export const PROFILE_TAGS: ProfileTag[] = [
  { label: 'Member', icon: MemberSVG },
  { label: 'Employee', icon: EmployeeSVG },
  { label: 'Vaccinated', icon: VaccineSVG },
  { label: 'friends & family', icon: EmojiLovedSVG },
  { label: 'Vax exemption', icon: VaxExemption1SVG },
  { label: 'Vax exemption', icon: VaxExemption2SVG },
];

export const ENHANCEMENT_RECOMMENDATIONS: EnhancementRecommendation[] = [
  {
    er_id: 'er_1',
    icon: ER_1,
    er_label: 'Gua Sha',
    selected: true,
  },
  {
    er_id: 'er_2',
    icon: ER_2,
    er_label: 'Light Therapy',
    selected: false,
  },
  {
    er_id: 'er_1',
    icon: ER_3,
    er_label: 'Microderm',
    selected: false,
  },
  {
    er_id: 'er_1',
    icon: ER_4,
    er_label: 'Peel',
    selected: false,
  },
];

export const QUESTION_LABELS: QuestionLabel = {
  seasonalAllergies: 'Do you experience seasonal allergies?',
  topicalAlergies: 'Do you have any topical allergies?',
  topicalAlergiesAdditionalResp: '',
  rednesOrBurning:
    'Do you ever experience redness, burning, or itching on your skin?',
  accutaneIsotretinoin:
    'Are you currently taking or have you taken Accutane/Isotretinoin?',
  rx_medications: 'Are you taking any of the following Rx medications?',
  rxTopicals: 'Rx Topicals',
  hormoneConsiderations: 'Should we take these into considerations?',
  diagnoses: 'Have you been diagnosed with any of these conditions?',
  electricity: 'Do you currently have any of these?',
  facialSurgery: 'Have you had facial surgery within the last 6 months?',
  skincareKnowledge: 'Skincare Knowledge Rating',
  atHomeProducts: 'At home products',
  activeIngredients: 'Active ingredients',
  gender: 'What gender do you identify with?',
  birthday: "When's your Birthday?",
  zipCode: "What's your zip code?",
  source: 'How did you hear about Heyday?',
  breakouts: 'How would you describe your history with breakouts?',
  oilyShine: 'Do you ever experience an oily shine?',
  facialFrequency: 'Ever had a facial?',
};

export const SKIN_PROFILE_SECTIONS: SkinProfileSectionType[] = [
  {
    sectionID: 'summary',
    sectionTitle: 'Summary',
  },
  {
    sectionID: 'sensitivities',
    sectionTitle: 'Sensitivities',
    sectionIcon: SPSensitivities,
    sectionQuestions: [
      {
        id: 'seasonalAllergies',
        questionTitle: 'Seasonal allergies',
        label: QUESTION_LABELS.seasonalAllergies,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        mandatory: true,
        summaryIcon: Leaf,
      },
      {
        id: 'topicalAlergies',
        questionTitle: 'Topical Allergies',
        label: QUESTION_LABELS.topicalAlergies,
        includeOtherVal: INTAKE_DEFAULT_OTHER,
        questionType: SkinProfileQuestionTypes.checkbox,
        note: '(Select all that apply)',
        options: [
          { label: 'Nuts', value: 'nuts' },
          { label: 'Fruit', value: 'fruit' },
          { label: 'Soy', value: 'soy' },
          { label: 'Seeds', value: 'seeds' },
          { label: 'Algae', value: 'algae' },
          { label: 'Salicylic Acid', value: 'salicylicAcid' },
          { label: 'Beeswax', value: 'beeswax' },
          { label: 'Aspirin', value: 'aspirin' },
          { label: 'Other Topical Allergies', value: 'other' },
          { label: 'None', value: 'none' },
        ],
        otherField: '',
        mandatory: true,
        summaryIcon: WarningCircle,
      },
      {
        id: 'rednesOrBurning',
        questionTitle: 'Redness / Burning',
        label: QUESTION_LABELS.rednesOrBurning,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        mandatory: true,
        summaryIcon: Fire,
      },
      {
        id: 'accutaneIsotretinoin',
        questionTitle: 'Accutane / Isotretinoin',
        label: QUESTION_LABELS.accutaneIsotretinoin,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Yes', value: 'currentlyTaking' },
          {
            label: 'Stopped within the last 6 months',
            value: 'stoppedLast6Months',
          },
          { label: 'Stopped over 6 months ago', value: 'stoppedOver6Months' },
          { label: 'No', value: 'no' },
        ],
        mandatory: true,
        summaryIcon: CirclesThree,
      },
      {
        id: 'rx_medications',
        questionTitle: 'RX Oral Medications',
        label: QUESTION_LABELS.rx_medications,
        questionType: SkinProfileQuestionTypes.checkbox,
        options: [
          { label: 'Oral Antibiotics', value: 'oralAntibiotics' },
          { label: 'Topical Antibiotics', value: 'topicalAntibiotics' },
          { label: 'Steroids', value: 'steroids' },
          { label: 'Antifungals', value: 'antiFungals' },
          { label: 'Antivirals', value: 'antiVirals' },
          { label: 'Immunosuppressants', value: 'immunosuppressants' },
          { label: 'Blood Thinners', value: 'bloodThinners' },
          { label: 'None', value: 'none' },
        ],
        mandatory: true,
        summaryIcon: rxBottle,
      },
      {
        id: 'rxTopicals',
        questionTitle: 'RX topicals',
        label: QUESTION_LABELS.rxTopicals,
        includeOtherVal: INTAKE_YES,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        otherField: '',
        mandatory: true,
        summaryIcon: prescription,
      },
      {
        id: 'hormoneConsiderations',
        questionTitle: 'Hormone Considerations',
        label: QUESTION_LABELS.hormoneConsiderations,
        questionType: SkinProfileQuestionTypes.checkbox,
        note: '(Select all that apply)',
        options: [
          {
            label: 'Pregnancy-Safe Products / Nursing',
            value: 'pregnancySafe',
          },
          { label: 'Hormone Therapy', value: 'hormoneTherapy' },
          { label: 'Birth Control', value: 'birthControl' },
          { label: 'None', value: 'none' },
        ],
        mandatory: true,
        summaryIcon: Baby,
      },
      {
        id: 'diagnoses',
        questionTitle: 'Diagnoses',
        label: QUESTION_LABELS.diagnoses,
        note: '(Select all that apply)',
        questionType: SkinProfileQuestionTypes.checkbox,
        options: [
          { label: 'Rosacea', value: 'rosacea' },
          { label: 'Asthma', value: 'asthma' },
          { label: 'Eczema', value: 'eczema' },
          { label: 'Epilepsy', value: 'epilepsy' },
          { label: 'Diabetes', value: 'diabetes' },
          { label: 'Current or Recent Cancer Treatment', value: 'cancer' },
          { label: 'None', value: 'none' },
        ],
        mandatory: true,
        summaryIcon: Atom,
      },
      {
        id: 'electricity',
        questionTitle: 'Electricity',
        label: QUESTION_LABELS.electricity,
        note: '(Select all that apply)',
        questionType: SkinProfileQuestionTypes.checkbox,
        options: [
          { label: 'Pacemaker', value: 'pacemaker' },
          { label: 'Metal Implants', value: 'metalImplants' },
          { label: 'Facial Piercings', value: 'facialPiercings' },
          { label: 'None', value: 'none' },
        ],
        mandatory: true,
        summaryIcon: Heartbeat,
      },
      {
        id: 'facialSurgery',
        questionTitle: 'Facial surgery',
        label: QUESTION_LABELS.facialSurgery,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        mandatory: true,
        summaryIcon: Robot,
      },
    ],
  },
  {
    sectionID: 'oil-activity',
    sectionTitle: 'Oil Activity',
    sectionIcon: SPOilAcitivity,
    sectionQuestions: [
      {
        id: 'oilyShine',
        questionTitle: 'Oily shine',
        label: QUESTION_LABELS.oilyShine,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
          { label: 'Occasionally', value: 'occasionally' },
        ],
        mandatory: true,
        summaryIcon: Robot,
      },
      {
        id: 'breakouts',
        questionTitle: 'Breakouts',
        label: QUESTION_LABELS.breakouts,
        note: '(Select all that apply)',
        questionType: SkinProfileQuestionTypes.checkbox,
        options: [
          { label: 'I rarely experience breakouts', value: 'rarelyExperience' },
          {
            label: 'I frequently experience breakouts',
            value: 'frequentlyExperience',
          },
          {
            label: 'I experience breakouts on my chest and back',
            value: 'chestAndBack',
          },
          {
            label: 'I have experienced breakouts since puberty',
            value: 'experiencedSincePuberty',
          },
          { label: 'Breakouts run in my family', value: 'runInFamily' },
        ],
        mandatory: true,
        summaryIcon: Robot,
      },
    ],
  },
  {
    sectionID: 'skincare-background',
    sectionTitle: 'Skincare Background',
    altSectionTitle: 'You + Skincare',
    sectionIcon: SPSkincare,
    sectionQuestions: [
      {
        id: 'facialFrequency',
        questionTitle: 'Facial frequency',
        label: QUESTION_LABELS.facialFrequency,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'First time getting a facial!', value: 'firstTime' },
          { label: 'Yes, but not regularly!', value: 'yes' },
          { label: 'Frequently', value: 'frequently' },
        ],
        mandatory: true,
        summaryIcon: Robot,
      },
      {
        id: 'skincareKnowledge',
        questionTitle: 'Skincare knowledge',
        label: QUESTION_LABELS.skincareKnowledge,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Newbie', value: 'newbie' },
          { label: 'Pretty good', value: 'prettyGood' },
          { label: 'Savvy', value: 'savvy' },
        ],
        mandatory: true,
        summaryIcon: Robot,
      },
      {
        id: 'atHomeProducts',
        questionTitle: 'At home products',
        label: QUESTION_LABELS.atHomeProducts,
        questionType: SkinProfileQuestionTypes.checkbox,
        options: [
          { label: 'Cleanser', value: 'cleanser' },
          { label: 'Exfoliant', value: 'exfoliant' },
          { label: 'Mask', value: 'mask' },
          { label: 'Toner', value: 'toner' },
          { label: 'Serum', value: 'serum' },
          { label: 'Moisturizer', value: 'moisturizer' },
          { label: 'Eye Cream', value: 'eyeCream' },
          { label: 'Facial Oil', value: 'facialOil' },
          { label: 'Sunscreen', value: 'sunscreen' },
        ],
        mandatory: true,
        summaryIcon: Robot,
      },
      {
        id: 'activeIngredients',
        questionTitle: 'Active ingredients',
        label: QUESTION_LABELS.activeIngredients,
        questionType: SkinProfileQuestionTypes.radio,
        options: [{ label: 'Yes', value: 'yes' }],
        mandatory: true,
        summaryIcon: Robot,
      },
    ],
  },
  {
    sectionID: 'basics',
    sectionTitle: 'Basics',
    sectionIcon: SPBasic,
    sectionQuestions: [
      {
        id: 'birthday',
        questionTitle: 'Birthday',
        label: QUESTION_LABELS.birthday,
        questionType: SkinProfileQuestionTypes.date,
        mandatory: true,
        defaultError: 'Date format should be mm/dd/yyyy',
      },
      {
        id: 'zipCode',
        questionTitle: 'Zip code',
        label: QUESTION_LABELS.zipCode,
        questionType: SkinProfileQuestionTypes.zipcode,
        mandatory: true,
        defaultError: 'Must be a valid U.S. zip code',
        additionalLabel: 'I live outside the US',
        additionalLabelResponse: OUTSIDE_US,
      },
      {
        id: 'gender',
        questionTitle: 'Gender',
        label: QUESTION_LABELS.gender,
        questionType: SkinProfileQuestionTypes.radio,
        options: [
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
          { label: 'Non-binary', value: 'nonBinary' },
          { label: 'Not listed', value: 'notListed' },
          { label: 'Would prefer not to answer', value: 'noAnswer' },
        ],
        mandatory: true,
      },
      {
        id: 'source',
        questionTitle: 'Source',
        label: QUESTION_LABELS.source,
        questionType: SkinProfileQuestionTypes.checkbox,
        options: [
          { label: 'Friend or Colleague', value: 'friendOrColleague' },
          {
            label: 'Print or Digital Publication',
            value: 'digitalPublication',
          },
          { label: 'An Influencer', value: 'influencer' },
          { label: 'Social Media', value: 'socialMedia' },
          { label: 'Google', value: 'google' },
          { label: 'Walked by the shop', value: 'walkIn' },
          { label: 'An event Heyday was at', value: 'event' },
        ],
        mandatory: true,
      },
    ],
  },
];

export const calendarColors = [
  {
    color: 'var(--sky-75)',
    alpha: 'var(--sky-33)',
  },
  {
    color: 'var(--positive-primary)',
    alpha: 'var(--positive-primary-33)',
  },
  {
    color: 'var(--honey-75)',
    alpha: 'var(--honey-33)',
  },
  {
    color: 'var(--turmeric-100)',
    alpha: 'var(--turmeric-33)',
  },
  {
    color: 'var(--neutral-tertiary-darker)',
    alpha: 'var(--neutral-tertiary-darker-33)',
  },
  {
    color: 'var(--peony-75)',
    alpha: 'var(--peony-33)',
  },
  {
    color: 'var(--pomegranate-100)',
    alpha: 'var(--pomegranate-33)',
  },
  {
    color: 'var(--lavender-100)',
    alpha: 'var(--lavender-33)',
  },
];

export const ADDITIONAL_RESPONSE = 'AdditionalResp';
export const DATE_FORMAT = 'MMM D, YYYY @ hA';
export const DATE_FORMAT_WITHOUT_TIME = 'MMM D, YYYY';
export const INVALID_PRODUCT_VARIANT_ID =
  "'00000000-0000-0000-0000-000000000000'";

export const TYPES: FilterType[] = [
  {
    id: 'front',
    label: 'All Front Bar',
    name: 'All Front Bar',
  },
  {
    id: 'back',
    label: 'All Back Bar',
    name: 'All Back Bar',
  },
];

export const PRODUCTS: FilterType[] = [
  {
    id: 'nut',
    label: 'Nut',
    name: 'Nut',
  },
  {
    id: 'fruit',
    label: 'Fruit',
    name: 'Fruit',
  },
  {
    id: 'soy',
    label: 'Soy',
    name: 'Soy',
  },
  {
    id: 'seed',
    label: 'Seed',
    name: 'Seed',
  },
  {
    id: 'algae',
    label: 'Algae',
    name: 'Algae',
  },
  {
    id: 'salicylicates',
    label: 'Salicylicates',
    name: 'Salicylicates',
  },
  {
    id: 'beeswax',
    label: 'Beeswax',
    name: 'Beeswax',
  },
];

export const KNACK_DATA_KEYS = [
  'treatedBy',
  'appointmentTime',
  'skinType',
  'conditions',
  'generalNotes',
  'treatmentType',
  'topTreatmentFocus',
  'cleanseBaseProduct',
  'cleanseBoost',
  'cleanseModality',
  'exfoliationBaseProduct',
  'exfoliationBoost',
  'exfoliationModality',
  'exfoliationEnhancement',
  'microdermSuctionRate',
  'microdermPasses',
  'peelProduct',
  'peelLayers',
  'peelTimeOnSkin',
  'extractionsPrepProducts',
  'extractionsBoost',
  'extractionsZone',
  'extractionsModality',
  'extractionsPostProducts',
  'touchPointBaseProduct',
  'touchPointBoost',
  'touchPointModality',
  'touchPointEnhancement',
  'maskBaseProduct',
  'maskBoost',
  'maskModality',
  'maskZoneSpecificDetails',
  'targetedTherapyEnhancements',
  'ledPanelTime',
  'ledPanelBoost',
  'toner',
  'serum',
  'moisturizerAndSpf',
  'eyeAndLip',
  'rehydrateBoost',
  'enhMicroderm',
  'enhPeel',
  'currentTreatmentFocus',
  'nextTimeEnhancement',
  'noteForCustomer',
  'products',
  'locationName',
];

export const LOCAL_STORAGE_KEYS = {
  LOGGED_IN_STAFF_BOULEVARD_ID: 'logged_in_staff_boulevard_id',
  LOGGED_IN_STAFF_STAFF_ID: 'logged_in_staff_staff_id',
  LOGGED_IN_STAFF_LOCATIONS: 'logged_in_staff_locations',
  SELECTED_LOCATION_ID: 'selected_location_id',
  STAFF_FOR_LOCATION_ID: 'staff_for_location_id',
  STAFF_SHIFT_FOR_LOCATION_ID: 'staff_shift_for_location_id',
  S3_HEALTH_INTAKE: 's3_health_intake',
  S3_TREATMENT_JSON: 's3_treatment_json',
  S3_TREATMENT_JSON_VERSION: 's3_treatment_json_version',
  THOUGHT_OF_THE_DAY_INDEX: 'thought_of_the_day_index',
  THOUGHT_OF_THE_DAY_DATE: 'thought_of_the_day_date',
};

export const FORMAT_DATE = {
  DAY: 'YYYY-MM-DD',
  DISPLAY_DAY: 'MM/DD/YYYY',
  DAY_BY_MONTH: 'MMM DD, YYYY',
  FULL_DATE: 'MMM DD, YYYY @ hA',
  DAY_LOWER: 'yyyy-MM-dd',
  TIME_MIN: 'h:mma',
  TIME: 'h:mmaaa',
  TIME_CAPITAL: 'h:mm aaa',
};

export const PARSE_DATE = {
  TIME: 'hh:mm a',
  TIME_24: 'HH:mm:ss',
};

export const APPOINTMENT_STATES = {
  CANCELLED: 'CANCELLED',
  BOOKED: 'BOOKED',
  ACTIVE: 'ACTIVE',
  CONFIRMED: 'CONFIRMED',
  ARRIVED: 'ARRIVED',
  FINAL: 'FINAL',
};

export const UTC_OFFSETS: iUTCOffset = {
  'america/new_york': 4,
  'america/chicago': 5,
  'america/los_angeles': 7,
  'America/Honolulu': 9,
};

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const NOT_REQUIRED_FORMSTATE_KEYS = [
  'errors',
  'client_message',
  'clientMessage',
  'knackId',
  'customerId',
  'sourceCustomerId',
  'locationId',
  'enteredBy',
  'sourceEnteredBy',
  'sourceTreatedBy',
  'appointmentTime',
  'createdAt',
  'generalNotes',
  'groupAppointment',
  'currentTreatmentCount',
  'internalNotes',
  'skinRecommendationNote',
  'nextTrTimeframe',
  'noteForCustomer',
  'appointmentId',
  'healthInfoSnapshot',
  'pastTreatmentData',
  'createdAt',
  'appointment',
  'accutane',
  'customerBookerId',
  'paymentStatus',
  'btMicroDesc',
  'btMicro',
  'firstTreatment',
  'revenue',
  'otherFocusNextTime',
  'focusNextTime',
  'otherFocus',
  'currentTreatmentFocus',
  'note',
  'location',
  'customer',
  'treated_by_staff',
  'maskZoneSpecificDetails',
  'entered_by_staff',
  'treatmentByStaff',
  'enteredByStaff',
];

export enum PRODUCT_TYPE {
  backBar = 'back',
  frontBar = 'front',
  both = 'both',
}

export enum BACKBAR_TYPE {
  original = 'Original Back Bar',
  heyday = 'Heyday Back Bar',
}

export enum NOTE_TYPE {
  noteForCustomer = 'note_for_customer',
  generalNotes = 'general_notes',
}

export const EXCLUDE_CHARACTERS_ON_NUMBER_TYPE = ['e', 'E', '+', '-', '.'];

export const EXCLUDED_PRODUCT_TYPES = [
  'GWP',
  'Membership',
  'Facial Package',
  'Pre-Pay Membership',
  'Backbar',
  'Gift Card',
  'Facial Service',
  'Fee',
  'Voucher',
];
