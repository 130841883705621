import React, { useReducer } from 'react';
import { TLoadingState } from './types';
import { loadingReducer } from './loadingReducer';
import { LoadingContext } from './loadingContext';

const initialState: TLoadingState = {
  clientDetailsLoading: false,
  isTreatmentLoading: false,
};

export const LoadingStateProvider: React.FC = ({ children }) => {
  const [loadingState, loadingDispatch] = useReducer(
    loadingReducer,
    initialState
  );

  return (
    <LoadingContext.Provider value={{ loadingState, loadingDispatch }}>
      {children}
    </LoadingContext.Provider>
  );
};
