import { AuthProvider } from '../providers/auth0Provider';
import { FC } from 'react';
import Layout from '../containers/Layout';
import { LoadingStateProvider } from '../store/loading/loadingProvider';

const ShopAppRouter: FC = () => {
  return (
    <AuthProvider>
      <LoadingStateProvider>
        <Layout />
      </LoadingStateProvider>
    </AuthProvider>
  );
};

export default ShopAppRouter;
